import React, { useState, useEffect } from 'react';

import Lista from '../../../Components/Lista';
import ConnectionUtil from "../../../utils/connection";
import {Card} from "../../../Components/Card";


export default function SociosDeudores(){

    const [socios, setSocios] = useState([]);

    useEffect(() =>{
        ConnectionUtil.get("http://localhost:8080/socios/deudores").then(data => setSocios(data));
    },[]);

    const columnas = [
        {header:'DNI', campo:'dni'},
        {header:'Nombre', campo:'nombre'},
        {header:'Deuda', campo:'deuda'},
    ];

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 ml-auto mr-auto">
                    <div className="card card-login">
                        <Card titulo={"Socios deudores"}>
                            <Lista elementos={socios} columnas={columnas} noHayElementos={"No hay socios deudores"} />
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}