import React from "react";

export default function Lista(...args){
    const [props] = args;
    const {elementos, columnas, noHayElementos} = props;


    return (!elementos || !elementos.length ) ? <>{noHayElementos}</>  : (
        <table className="table">
            <thead>
            <tr>
                {columnas.map(c => <th scope={"col"}>{c.header}</th>)}
            </tr>
            </thead>
            <tbody>
                {elementos.map(e => <ItemLista elemento={e} columnas={columnas}/>)}
            </tbody>
        </table>
    );
}

function ItemLista(...args){
    const [props] = args;
    const {elemento, columnas} = props;

    return (
        <tr>
            {columnas.map(c => {
                const { handleClick= () => {} , campo, campoFunc, valor} = c;
                return <td onClick={(...args) => handleClick(elemento, ...args) }>{campoFunc ? campoFunc(elemento) : (campo ? elemento[campo] : valor)}</td>
            })}
        </tr>
    );
}