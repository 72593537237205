import QrReader from "react-qr-scanner";
import {useState} from "react";

export default function EncontrarSocio(...args){
    const [props] = args;
    const {onSocioChange = () =>{}, startShowingQR = false} = props;
    const [showQR, setShowQR] = useState(startShowingQR);
    const [socio, setSocio] = useState();
    const [QRErrorMessage, setQRErrorMessage] = useState();

    function toogleShowQR(){
        setShowQR(!showQR);
    }

    function handleSocioChange(data){
        if(data){
            const {text: socio} = data;
            setSocio(socio);
            onSocioChange(socio);
        }
    }

    function handleQRScanError(err){
        setQRErrorMessage(err);
    }

    const previewStyle = {
        height: 240,
        width: "100%"
    }

    return (
        <>
            <div className="input-group">
                <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="material-icons">badge</i>
                        </span>
                </div>
                <input type="text" className="form-control" name="dniSocio" placeholder="Numero de socio / DNI" value={socio} />
            </div>
            {/*<div className="container">*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-lg-12 col-md-12 ml-auto mr-auto">*/}
            {/*            <button onClick={toogleShowQR} className="btn btn-primary btn-round">*/}
            {/*                <i className="material-icons">qr_code_scanner</i> {showQR ? 'Ocultar' : 'Mostrar'} lector QR*/}
            {/*            </button>*/}
            {/*            {showQR &&*/}
            {/*                <>*/}
            {/*                    <QrReader delay={500} style={previewStyle} onError={handleQRScanError} onScan={handleSocioChange} />*/}
            {/*                    {socio}*/}
            {/*                    {QRErrorMessage}*/}
            {/*                </>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}