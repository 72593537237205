class ConnectionUtil{

    API_URL = '/.netlify/functions';

    getFromAPI(url){
        return this.get(`${this.API_URL}${url}`);
    }

    postToAPI(url){
        return this.post(`${this.API_URL}${url}`);
    }

    get(url){
        return this.jsonParseResponse(fetch(url), this.getGETHeaders());
    }

    post(url, data){
        return fetch(url,{ ...this.getPOSTHeaders(), body: JSON.stringify(data)});
    }


    jsonParseResponse(fetchPromise){
        return fetchPromise.then(res => res.json())
    }

    getGETHeaders(){
        return {
            method: 'GET'
        }
    }

    getPOSTHeaders(){
        return {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            }
        }
    }

}

export default new ConnectionUtil();