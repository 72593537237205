import React from "react";

import Card from './Card';

export default function FormCard(...args){
    const [props] = args;
    const {onSubmit = () =>{}, submitText, ...otherProps } = props;

    function handleSubmit(event){
        onSubmit(event.target);
        event.preventDefault();
    }

    const footer = <input type={"submit"} className="btn btn-primary btn-link btn-wd btn-lg" value={submitText}/>;
    return (
        <form className="form" method="" action="" onSubmit={handleSubmit} style={{"min-height":"unset"}}>
            <Card {...otherProps} footer={footer} />
        </form>
    );
}