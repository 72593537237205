import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import NavBar from "../Components/NavBar";
import Modal from "../Components/Modal";

import {AprobarSocios, DetalleDeSocio, SociosDeudores, Home, IngresarPago, Informes, BuscarSocio} from "./views";
import './style.css';

export default function App() {
    const [drawModal, setDrawModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    function showModal(content){
        setModalContent(content);
        setDrawModal(true);
    };

    function hideModal(){
        setDrawModal(false);
    };

    return (
        <div className="App">
            <Router>
                <div>
                    <NavBar/>
                </div>
                <Modal drawModal={drawModal} {...modalContent} hideModal={hideModal}/>
                <div className="page-header" >
                    <Switch>
                        <Route path="/aprobarSocios">
                            <AprobarSocios showModal={showModal} />
                        </Route>
                        <Route path="/buscarSocio">
                            <BuscarSocio />
                        </Route>
                        <Route path="/socio/:id">
                            <DetalleDeSocio/>
                        </Route>
                        <Route path="/sociosDeudores">
                            <SociosDeudores/>
                        </Route>
                        <Route path="/ingresarPago">
                            <IngresarPago/>
                        </Route>
                        <Route path="/informes">
                            <Informes/>
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </div>
    );
}