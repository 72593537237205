import { Link } from 'react-router-dom'

export default function NavBarItem(...args){
    const [props] = args;
    const {nombre, url} = props;

    return (
        <li className="nav-item">
            <Link to={url} className="nav-link">{nombre}</Link>
        </li>
    );
}