import React from 'react';
import { useHistory } from "react-router-dom";

import { FormCard } from "../../../Components/Card";
import EncontrarSocio from "../../../Components/EncontrarSocio";

export default function BuscarSocio(){
    const history = useHistory();

    function searchSocio(target){
        const [{value:dniSocio}] = target;
        history.push(`/socio/${dniSocio}`);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 ml-auto mr-auto">
                    <div className="card card-login">
                        <FormCard titulo={"Buscar socio"} submitText={"Buscar"} onSubmit={searchSocio} >
                            <EncontrarSocio />
                        </FormCard>
                    </div>
                </div>
            </div>
        </div>
    );
}