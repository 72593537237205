import React from "react";

export default function Card(...args){
    const [props] = args;
    const {titulo, children:body, footer } = props;

    return (
        <>
            <div className="card-header card-header-primary text-center">
                <h4 className="card-title">{titulo}</h4>
            </div>
            <div className="card-body">
                {body}
            </div>
            <div className="footer text-center">
                {footer}
            </div>
        </>
    );
}