import React, { useState, useEffect } from 'react';

import Lista from '../../../Components/Lista';
import ConnectionUtil from "../../../utils/connection";
import {Card} from "../../../Components/Card";


export default function AprobarSocios(...args){
    const [props] = args;
    const {showModal} = props;

    const [solicitudes, setSolicitudes] = useState([]);

    useEffect(() =>{
        getSolicitudes();
    },[]);

    function getSolicitudes(){
        ConnectionUtil.get("http://localhost:8080/solicitudes").then(data => setSolicitudes(data));
    }

    function aprobarSocio(socio){
        const {dni} = socio;
        showModal({
            title: "Aprobar usuario",
            body: `Seguro que queres aprobar la solicitud de ${getNombreCompleto(socio)}`,
            buttonText: "Aprobar",
            closeButtonText: "Cancelar",
            onButtonClick: () => {
               ConnectionUtil.post(`http://localhost:8080/solicitudes/aprobar`, {dni})
                   .then(() => getSolicitudes())
           }
        });
    }

    function rechazarSocio(socio){
        const {dni} = socio;
        showModal({
            title: "Rechazar usuario",
            body: `Seguro que queres rechazar la solicitud de  ${getNombreCompleto(socio)}`,
            buttonText: "Rechazar",
            closeButtonText: "Cancelar",
            onButtonClick: () => {
                ConnectionUtil.post(`http://localhost:8080/solicitudes/rechazar`,{dni})
                    .then(() => getSolicitudes());
            }
        });
    }

    const getNombreCompleto = (socio) => `${socio.apellido}, ${socio.nombre}`;

    const columnas = [
        {header:'DNI', campo:'dni'},
        {header:'Nombre', campoFunc:getNombreCompleto},
        {header:'Pago', valor:'🔲',handleClick: aprobarSocio},
        {header:'Aprobar', valor:'✅',handleClick: aprobarSocio},
        {header:'Rechazar', valor:'❌',handleClick: rechazarSocio}
    ];

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 ml-auto mr-auto">
                    <div className="card card-login">
                        <Card titulo={"Aprobar socios "}>
                            <Lista elementos={solicitudes} columnas={columnas} noHayElementos={"No hay solicitudes de socios"}/>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}