import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

import ConnectionUtil from "../../../utils/connection";

export default function DetalleDeSocio(){
    const { id:dniSocio } = useParams();
    const [socio, setSocio] = useState(null);

    useEffect(() => {
        ConnectionUtil.getFromAPI(`/getSocio?id=${dniSocio}`).then(data => setSocio(data));
    },[]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 ml-auto mr-auto">
                    <div className="card card-login">
                        { socio && <MostrarSocio socio={socio} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

function MostrarSocio(...arg){
    const [props] = arg;
    const {socio = {}} = props;
    const {nombre, estaAlDia, dni, sexo, email, categoria, pagos =[]} = socio;

    return(
        <>
            Nombre: {nombre} <br/>
            DNI: {dni} <br/>
            Sexo: {sexo} <br/>
            Email: {email} <br/>
            Categoria: {categoria} <br/>
            Esta al dia: {estaAlDia ? "✅" : "❌"} ( Semaforo 🔴 mas de 2 meses 🟡 2 meses 🟢 es en curso ) <br/>
            Mandar mail "quisiste entrar, debes no cuelges" cuando se carga un socio en amarillo<br/>
            Ultima cuota paga: {pagos.map(p => `${p.fecha} ${p.mes} ${p.medio}`)} (solo si aparece en amarrillo/rojo)
        </>
    );

}