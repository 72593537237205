import React, { useState, useEffect } from 'react';

import Lista from '../../../Components/Lista';
import ConnectionUtil from "../../../utils/connection";
import {Card} from "../../../Components/Card";


export default function Informes(){

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 ml-auto mr-auto">
                    <div className="card card-login">
                        <ol>
                            <li>Pagos de los ultimos 15 dias</li>
                            <li>Exportar base a CSV</li>
                            <li>Solicitudes rechazadas</li>
                            <li>Socios proximos a ser vitalicios</li>
                            <li>Distintos informes a definir</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}