import NavBarItem from './NavBarItem';

export default function NavBar(){

    const secciones = [
        {nombre:"Buscar socios", url:"/buscarSocio"},
        {nombre:"Aprobar socios", url:"/aprobarSocios"},
        {nombre:"Ingresar pago", url:"/ingresarPago"},
        {nombre:"Socios deudores", url:"/sociosDeudores"},
        {nombre:"Informes", url:"/informes"},
    ];

    return (
        <nav className="navbar navbar-inverse navbar-expand-lg bg-dark" role="navigation-demo">
            <div className="container">
                <div className="navbar-translate">
                    <a className="navbar-brand" href="#0">Sistema de Socios del Club Portugues</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                        { secciones.map( ({nombre, url}, i) => <NavBarItem nombre={nombre} url={url} />) }
                        <li className="dropdown nav-item">
                            <a href="javascript:;" className="profile-photo dropdown-toggle nav-link"
                               data-toggle="dropdown">
                                <div className="profile-photo-small">
                                    <img src="/assets/img/faces/bandera.jpeg" alt="Circle Image"
                                         className="rounded-circle img-fluid" />
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <h6 className="dropdown-header">Administrador</h6>
                                <a href="/panelDeControl" className="dropdown-item">Panel de control</a>
                                <a href="javascript:;" className="dropdown-item">Salir</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );

}