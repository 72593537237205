import React, {useState} from 'react';

import { FormCard } from "../../../Components/Card";
import ConnectionUtil from "../../../utils/connection";
import EncontrarSocio from "../../../Components/EncontrarSocio";

export default function IngresarPago(){
    const [proximoPago, setProximoPago] = useState();

    function buscarUltimoPago(dniSocio){
        ConnectionUtil.get(`http://localhost:8080/socios/${dniSocio}`)
            .then(({proximoPago}) => setProximoPago("Febrero 2020"));
    }

    function ingresarPago(){
        ConnectionUtil.post('http://localhost:8080/pagos',{});
    }

    const mediosDePagos = [
        {valor:"mercadoLibre", texto:"MercadoLibre"},
        {valor:"efectivo", texto:"Efectivo"},
        {valor:"tarjeta", texto:"Tarjeta"}
    ]

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 ml-auto mr-auto">
                    <div className="card card-login">
                        <FormCard titulo={"Ingresar pago"} submitText={"Ingresar"} onSubmit={ingresarPago} >
                            <EncontrarSocio onSocioChange={buscarUltimoPago}/>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="material-icons">paid</i>
                                    </span>
                                </div>
                                <div className="input-group-prepend">
                                    <select name="medioDePago" className="form-control" placeholder="Medio de pago">
                                        <option disabled selected value>Medio de pago</option>
                                        {mediosDePagos.map(({valor, texto}) => <option value={valor}>{texto}</option>)}
                                    </select>
                                </div>
                                {proximoPago && <>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="material-icons">event</i>
                                        </span>
                                    </div>
                                    <div className="input-group-prepend">
                                        <select name="mesPago" className="form-control" placeholder="Medio de pago">
                                            <option disabled selected value>Febrero 2020</option>
                                        </select>
                                    </div>
                                </>}
                            </div>
                        </FormCard>
                    </div>
                </div>
            </div>
        </div>
    );
}