export default function Modal(...args){
    const [props] = args;
    const {title, body, buttonText, onButtonClick = () =>{}, closeButtonText, onClose = () =>{}, drawModal, hideModal } = props;

    function handleClose(){
        onClose();
        hideModal();
    }

    function handleButtonClick(){
        onButtonClick();
        hideModal();
    }

    const style= {
        "padding-right": "15px",
        display: "block"
    }

    return (drawModal && <div className="modal fade show" id="myModal" tabIndex="-1" role="dialog" style={style}>
            <div className="modal-dialog modal-dialog-centered" role="document" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={handleClose}>
                            <i className="material-icons">clear</i>
                        </button>
                    </div>
                    <div className="modal-body">
                        {body}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={handleButtonClick}>{buttonText}</button>
                        <button type="button" className="btn btn-danger btn-link" data-dismiss="modal"
                                onClick={handleClose}>{closeButtonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}